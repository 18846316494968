<template>
	<div>
		<validation-observer>
			<b-row>
				<b-col cols="6">
					<p>Take an action</p>
					<div class="border p-1 rounded mt-1" style="background-color: #f9f9f9">
						<div v-if="complaint.responses.length > 0" class="">
							<b-form-group label="How did you solve this complaint" label-for="solutionId">
								<validation-provider #default="{ errors }" name="Solution" rules="required">
									<v-select
										id="solutionId"
										v-model="solutionId"
										label="name"
										:options="solutions"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
										style="background-color: #fff"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<div class="d-flex justify-content-end">
								<b-button variant="primary" :disabled="complaint.closed_date" @click="close">Close complaint</b-button>
							</div>
						</div>
						<div v-else class="">
							<p class="ml-25">This complaint has no responses yet, so it can't be closed</p>
						</div>
					</div>
				</b-col>
			</b-row>
		</validation-observer>
	</div>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import store from '@/store';

export default {
	name: 'ClosingTab',

	components: { BRow, BCol, BFormGroup, BButton, ValidationProvider, ValidationObserver, vSelect },

	props: {
		complaint: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		solutions: [],
		solutionId: null,
	}),

	async created() {
		this.solutionId = this.complaint?.solution?.id;
		this.solutions = await store.dispatch('solutions/getSolutions');
	},

	methods: {
		async close() {
			await store.dispatch('complaints/close', {
				id: this.complaint.id,
				solution_id: this.solutionId,
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>
