<template>
	<div>
		<validation-observer ref="processRules">
			<b-row>
				<b-col cols="6">
					<div v-if="user.type === 10" class="">
						<p class="font-weight-bold mb-50">Processing type</p>
						<div>
							<p>Chose how you want to process this complaint</p>
							<b-form-radio-group v-model="processMode" :options="modes" buttons button-variant="outline-primary" />
						</div>
					</div>
					<div class="border p-1 rounded mt-1" style="background-color: #f9f9f9">
						<div v-if="user.type === 10 && processMode === 1">
							<b-form-group label="Transfer this complaint to" label-for="sendTo">
								<validation-provider #default="{ errors }" name="Send to" rules="required">
									<v-select
										id="sendTo"
										v-model="sendTo"
										label="name"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="managers"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
										style="background-color: #fff"
									>
										<template v-slot:option="option">
											<div class="d-flex justify-content-between">
												<span>{{ option.name }}</span>
												<span>({{ option.role }})</span>
											</div>
										</template>
									</v-select>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</div>
						<div v-else>
							<b-form-group label="How the complaint was handled?" label-for="text-handling">
								<validation-provider #default="{ errors }" name="Response text" rules="required">
									<b-form-textarea id="text-handling" v-model="response" rows="5" placeholder="How the complaint was handled?" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<!-- image -->
							<b-form-group label="Add attachments" label-for="image">
								<b-form-file id="image" placeholder="" multiple @input="parseFiles" />
							</b-form-group>
						</div>

						<div class="d-flex justify-content-end">
							<b-button variant="primary" @click="save">Save</b-button>
						</div>
					</div>
				</b-col>
			</b-row>
		</validation-observer>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BRow, BCol, BFormGroup, BFormRadioGroup, BFormTextarea, BFormFile, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import store from '@/store';
import { parseFileToBase64 } from '@/utils/helpers';

export default {
	name: 'ProcessTab',

	components: { BRow, BCol, BFormGroup, BFormRadioGroup, BFormTextarea, BFormFile, BButton, ValidationProvider, ValidationObserver, vSelect },

	props: {
		complaint: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		required,

		sendTo: null,
		response: '',
		image: [],
		processMode: 1,

		processModes: [
			{ text: 'Transfer to', value: 1 },
			{ text: 'Process internally', value: 2 },
		],

		project: null,
	}),

	computed: {
		...mapGetters('attributes', ['getFillers']),
		...mapState('auth', ['user']),

		managers() {
			if (this.project) {
				const { areaManager, areaAssistant, technical, consultants } = this.project.managers;
				return [
					{ id: areaManager.phone, name: areaManager.name, role: 'Area Manager' },
					{ id: areaAssistant.phone, name: areaAssistant.name, role: 'Area Assistant' },
					{ id: technical.phone, name: technical.name, role: 'Technical officer' },
					...consultants.map((consultant) => ({
						id: consultant.phone,
						name: consultant.name,
						role: 'Consultant',
					})),
				];
			}

			return [];
		},

		modes() {
			if (this.user.type === 10) {
				return [
					{ text: 'Transfer to', value: 1 },
					{ text: 'Process internally', value: 2 },
				];
			}

			return [];
		},
	},

	async created() {
		this.sendTo = this.complaint.current_handler;
		this.processMode = this.user.type !== 10 ? 2 : 1;
		this.project = await store.dispatch('projects/getProject', this.complaint.project.SP_PROJID);
	},

	methods: {
		parseFiles(files) {
			this.image = [];

			files.forEach(async (file) => {
				const base64Text = await parseFileToBase64(file);
				this.image.push(base64Text);
			});
		},

		async save() {
			const success = await this.$refs.processRules.validate();
			if (!success) return;

			if (this.processMode === 2) {
				const data = await store.dispatch('complaints/process', {
					id: this.complaint.id,
					user_id: this.user.id,
					response: this.response,
					image: JSON.stringify(this.image),
				});

				this.complaint.responses.push(data);
			} else {
				await store.dispatch('complaints/transfer', {
					id: this.complaint.id,
					handler: this.sendTo,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
