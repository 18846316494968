<template>
	<div>
		<b-row>
			<b-col cols="6" class="mb-2">
				<p>Select complaint category & subcategory</p>
				<validation-observer ref="categoriesRules">
					<div class="border p-1 rounded mt-1" style="background-color: #f9f9f9">
						<b-row>
							<!-- Category -->
							<b-col cols="12">
								<b-form-group label="Category" label-for="categoryId">
									<validation-provider #default="{ errors }" name="Category" rules="required">
										<v-select
											id="categoryId"
											v-model="complaint.category"
											label="name"
											:clearable="false"
											:filterable="false"
											:searchable="false"
											:options="categories"
											:reduce="(o) => o.id"
											:state="errors.length > 0 ? false : null"
											style="background-color: #fff"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<!-- Sub Category -->
							<b-col cols="12">
								<b-form-group label="Subcategory" label-for="subCategoryId">
									<validation-provider #default="{ errors }" name="Subcategory" rules="required">
										<v-select
											id="subCategoryId"
											v-model="complaint.sub_category"
											label="name"
											:clearable="false"
											:filterable="false"
											:searchable="false"
											:options="currentSubCategories"
											:reduce="(o) => o.id"
											:state="errors.length > 0 ? false : null"
											:disabled="!complaint.category"
											style="background-color: #fff"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>

								<div class="d-flex justify-content-end">
									<b-button variant="primary" @click="save">Save</b-button>
								</div>
							</b-col>
						</b-row>
					</div>
				</validation-observer>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import store from '@/store';

export default {
	name: 'CategorizeTab',

	components: { BRow, BCol, BFormGroup, BButton, ValidationProvider, ValidationObserver, vSelect },

	props: {
		complaint: {
			type: Object,
			required: true,
		},
		categories: {
			type: Array,
			required: true,
		},
	},

	data: () => ({
		required,
	}),

	computed: {
		currentSubCategories() {
			return this.categories.find((cat) => cat.id === this.complaint.category)?.children;
		},
	},

	watch: {
		'complaint.category': {
			handler(val) {
				if (val) {
					this.complaint.sub_category = null;
				}
			},
		},
	},

	methods: {
		async save() {
			const success = await this.$refs.categoriesRules.validate();
			if (!success) return;

			await store.dispatch('complaints/attachedCategory', this.complaint);
		},
	},
};
</script>

<style lang="scss" scoped></style>
