<template>
	<div>
		<b-row>
			<b-col cols="6" class="mb-2">
				<p class="font-weight-bold mb-2">List of resopnses</p>
				<div>
					<app-timeline v-if="complaint.responses.length > 0">
						<app-timeline-item v-for="(response, i) in complaint.responses" :key="i">
							<div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
								<h6>{{ response.user }}</h6>
								<small class="text-muted">{{ response.created_at.humans }}</small>
							</div>
							<div class="mt-25">
								<p class="p-75 rounded">{{ response.response }}</p>
								<div class="d-flex">
									<div v-for="img in response.attachments" :key="img" class="flex-shrink-1">
										<img :src="img" thumbnail class="image img-thumbnail" @click="openImage(img)" />
									</div>
								</div>
							</div>
						</app-timeline-item>
					</app-timeline>
					<div v-else class="pl-25">
						There is no responses to this complaint yet.
					</div>
				</div>
			</b-col>
		</b-row>
		<b-modal v-model="showImage" title="Response image" centered hide-footer>
			<img :src="selectedImage" class="d-block img-thumbnail w-100" />
		</b-modal>
	</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

export default {
	name: 'ResponsesTab',

	components: { BRow, BCol, AppTimelineItem, AppTimeline },

	props: {
		complaint: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		showImage: false,
		selectedImage: null,
	}),

	methods: {
		openImage(img) {
			this.selectedImage = img;
			this.showImage = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.image {
	display: block;
	max-width: 100px;
	max-height: 100px;
	width: auto;
	height: auto;
	cursor: pointer;
}
</style>
