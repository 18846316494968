<template>
	<div>
		<b-row>
			<b-col cols="6" class="mb-2">
				<div class="d-flex justify-content-between">
					<div class="">
						<div class="mb-2">
							<p class="p-title mb-25">Name</p>
							<p class="font-weight-bold ">{{ complaint.name }}</p>
						</div>
						<div class="">
							<p class="p-title mb-25">Status</p>
							<b-badge :variant="getGRMStatusColor(complaint.status)" class="p-50">{{ complaint.status }}</b-badge>
						</div>
					</div>
					<div class="">
						<div class="mb-2">
							<p class="p-title mb-25">Gender</p>
							<p class="font-weight-bold">{{ complaint.gender.name }}</p>
						</div>
						<div class="">
							<p class="p-title mb-25">Project code</p>
							<p class="font-weight-bold">{{ complaint.project.SP_PROJID }}</p>
						</div>
					</div>
					<div class="">
						<div class="mb-2">
							<p class="p-title mb-25">Phone</p>
							<p class="font-weight-bold">{{ complaint.phone }}</p>
						</div>
						<div class="">
							<p class="p-title mb-25">Project</p>
							<p class="font-weight-bold text-ellipsis">{{ complaint.project.SP_TITLE }}</p>
						</div>
					</div>
				</div>
				<div class="mt-2">
					<p class="mb-50 font-weight-bold">Complaint text</p>
					<p class="p-75 border rounded" style="background-color: #f9f9f9">{{ complaint.complaint }}</p>
				</div>
				<div class="mt-2">
					<p class="mb-50 font-weight-bold">Attachments</p>
					<div class="p-75 border rounded d-flex" style="background-color: #f9f9f9">
						<p v-if="!complaint.attachments.length" class="">No attachments yet</p>
						<div v-for="img in complaint.attachments" :key="img" class="flex-shrink-1">
							<img :src="img" thumbnail class="image img-thumbnail" @click="openImage(img)" />
						</div>
					</div>
				</div>
			</b-col>
			<b-col>
				<div v-if="!complaint.location.latitude" class="p-1 border rounded text-center" style="background-color: #f9f9f9">
					<h4>No location</h4>
					<p class="mt-2">
						This complaint was received via <strong>{{ complaint.type.name }}</strong>
					</p>
				</div>
				<report-map v-else :loc="[complaint.location.latitude, complaint.location.longitude]" title="Complaint Location" />
			</b-col>
		</b-row>
		<b-modal v-model="showImage" title="Report image" centered hide-footer>
			<img :src="selectedImage" class="d-block img-thumbnail w-100" />
		</b-modal>
	</div>
</template>

<script>
import { BRow, BCol, BModal, BBadge } from 'bootstrap-vue';
import ReportMap from '@/views/projects/details/ReportMap.vue';
import { getGRMStatusColor } from '@/utils/helpers';

export default {
	name: 'DetailsTab',

	components: { BRow, BCol, BModal, BBadge, ReportMap },

	props: {
		complaint: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		showImage: false,
		selectedImage: null,
	}),

	methods: {
		openImage(img) {
			this.selectedImage = img;
			this.showImage = true;
		},

		getGRMStatusColor,
	},
};
</script>

<style lang="scss" scoped>
.image {
	display: block;
	max-width: 150px;
	max-height: 150px;
	width: auto;
	height: auto;
	cursor: pointer;
}
</style>
