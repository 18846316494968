<template>
	<div>
		<div v-if="loading" class="loading">
			<!-- <div class="effect-1 effects"></div>
			<div class="effect-2 effects"></div>
			<div class="effect-3 effects"></div> -->
		</div>
		<b-tabs v-else pills class="mb-3">
			<!-- details tab -->
			<b-tab title="Details">
				<b-card header-class="border-bottom mb-1 justify-content-between">
					<template #header>
						<h4 class="card-title">Complaint information</h4>
						<b-button
							variant="outline-secondary"
							class="cursor-pointer ml-50"
							size="sm"
							:to="{ name: 'grm.complaints.details.report', params: { complaintId } }"
						>
							<span class="align-middle">Report </span>
						</b-button>
					</template>

					<details-tab :complaint="complaint" />
				</b-card>
			</b-tab>

			<!-- details tab -->
			<b-tab v-if="haveAccess" title="Categorize">
				<b-card header-class="border-bottom mb-1">
					<template #header>
						<h4 class="card-title">Complaint categorization</h4>

						<div class="d-flex align-items-center">
							<span class="mr-50">Status</span>
							<b-badge :variant="getGRMStatusColor(complaint.status)" class="p-50">{{ complaint.status }}</b-badge>
						</div>
					</template>

					<categoriez-tab :complaint="complaint" :categories="categories" />
				</b-card>
			</b-tab>

			<b-tab title="Processing">
				<b-card header-class="border-bottom mb-1">
					<template #header>
						<h4 class="card-title">Complaint processing</h4>

						<div class="d-flex align-items-center">
							<span class="mr-50">Status</span>
							<b-badge :variant="getGRMStatusColor(complaint.status)" class="p-50">{{ complaint.status }}</b-badge>
						</div>
					</template>

					<process-tab :complaint="complaint" />
				</b-card>
			</b-tab>

			<!-- Responses  -->
			<b-tab v-if="haveAccess" :title="`Responses (${complaint.responses.length})`">
				<b-card header-class="border-bottom mb-1">
					<template #header>
						<h4 class="card-title">Complaint responses</h4>

						<div class="d-flex align-items-center">
							<span class="mr-50">Status</span>
							<b-badge :variant="getGRMStatusColor(complaint.status)" class="p-50">{{ complaint.status }}</b-badge>
						</div>
					</template>

					<responses-tab :complaint="complaint" />
				</b-card>
			</b-tab>
			<b-tab v-if="haveAccess" title="Closing">
				<b-card header-class="border-bottom mb-1">
					<template #header>
						<h4 class="card-title">Complaint closing</h4>

						<div class="d-flex align-items-center">
							<span class="mr-50">Status</span>
							<b-badge :variant="getGRMStatusColor(complaint.status)" class="p-50">{{ complaint.status }}</b-badge>
						</div>
					</template>

					<closing-tab :complaint="complaint" />
				</b-card>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { BCard, BTabs, BButton, BBadge, BTab } from 'bootstrap-vue';
import { required } from '@validations';
import { getGRMStatusColor } from '@/utils/helpers';

import DetailsTab from './details-tab.vue';
import CategoriezTab from './categoriez-tab.vue';
import ProcessTab from './process-tab.vue';
import ResponsesTab from './responses-tab.vue';
import ClosingTab from './closing-tab.vue';

export default {
	name: 'ComplaintDetails',

	components: {
		BCard,
		BTabs,
		BButton,
		BBadge,
		BTab,

		DetailsTab,
		CategoriezTab,
		ProcessTab,
		ResponsesTab,
		ClosingTab,
	},

	props: {
		complaintId: [Number, String],
	},

	data: () => ({
		complaint: undefined,
		categories: [],
		loading: false,
		required,
	}),

	computed: {
		...mapState('auth', ['user']),

		haveAccess() {
			return [10, 4].includes(this.user.type);
		},
	},

	created() {
		this.loadComplaint();
	},

	methods: {
		async loadComplaint() {
			try {
				this.loading = true;
				this.categories = await store.dispatch('categories/getCategories');
				this.complaint = await store.dispatch('complaints/getComplaint', parseInt(this.complaintId, 10));
			} catch (error) {
				console.error(error);
			}

			this.loading = false;
		},

		getGRMStatusColor,
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
