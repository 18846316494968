<template>
	<b-card>
		<h4 class="text-center mb-1">{{ title }}</h4>
		<l-map v-if="ready" :zoom="zoom" :center="loc">
			<l-tile-layer :url="url" />
			<l-marker :lat-lng="loc"> </l-marker>
		</l-map>
	</b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
	name: 'ReportMap',

	components: {
		LMap,
		LTileLayer,
		LMarker,
		BCard,
	},

	props: {
		title: {
			type: String,
			default: '',
		},
		loc: {
			type: Array,
			default: () => [15.354880290596578, 44.174212608591695],
		},
	},

	data() {
		return {
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			zoom: 20,
			ready: false,
		};
	},

	created() {
		// eslint-disable-next-line no-return-assign
		setTimeout(() => (this.ready = true), 50);
	},
};
</script>

<style lang="scss">
.vue2leaflet-map {
	&.leaflet-container {
		height: 320px;
	}
}
</style>
